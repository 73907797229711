// import { useContext } from "react"
// import { AppState } from "../App"
import Loadingcontrol from "./loadingControl"
import Control from "./control"
import "./loading.css"


export default function Loading() {
    //     const state = useContext(AppState)
    return (
        <div className="loader-page">
            <Loadingcontrol />
            <div className="loader-pagewrapper">
                {/* <span className="loader"></span> */}
                <span className="loaderWord">The ad was not found or has been removed.</span>
            </div>
        </div>
    )
}