import { useRef, useContext, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { AppState } from "../App"
import share from "../img/share.svg"
import notFound from '../img/noImage.png'
import info from "../img/info.svg"
import "./userPlate.css"

export default function UserPlate({ data }) {
    const state = useContext(AppState)
    const thisUrl = data.id
    const shareButton = useRef()
    const [compState, setCompState] = useState({
        imageLoaded: true,
        thisUrl : data.id
    })

    function descriptionSwitcher(value) {
        try {
            fetch(`https://indoads.terrapay.online/api/items/${compState.thisUrl}`)
                .then(response => response.json())
                .then(json => state.dispatch({ type: "open_description", payload: json.description }))
        } catch {
            console.warn("Error getting description")
        }
    }
    function shareButtonFunc(event) {
        event.preventDefault()
        if (navigator.share) {
            navigator.share({
                url: "https://t.me/indo_ads_bot/indoads_app?startApp=" + compState.thisUrl + "&startapp=" + compState.thisUrl
            })
                .catch(console.error);
        } else {
            navigator.clipboard.writeText("https://t.me/indo_ads_bot/indoads_app?startApp=" +compState.thisUrl+ "&startapp=" + compState.thisUrl)
            state.dispatch({ type: "popup" })
        }
    }
    function priceFormatting(value) {
        return Number(value).toLocaleString("ru")
    }
    function locationCutter(value) {
        if (value.length >= 13) {
            return value.slice(0, 13) + "..."
        } else {
            return value
        }
    }
    function categoriesCutter(value) {
        if (value.length >= 13) {
            return value.slice(0, 13) + "..."
        } else {
            return value
        }
    }
    function phoneCutter(value) {
        if (value.length > 12) {
            return value.slice(0, 11) + "..."
        } else {
            return value
        }
    }
    function checkImage() {
        setCompState({ ...compState, imageLoaded: false })
    }

    function dateFormatingAlter(value) {
        let temporalArray = value.split("-")
        return temporalArray[2].slice(0, 2) + "." + temporalArray[1] + "." + temporalArray[0]
    }


    return (
        <>
            <div className="userPlateMobile">
                <header className="upm-header">
                    {/* <div className="ump-colors">
                    <div></div>
                    <div></div>
                    <div></div>
                </div> */}
                    <span className="upm-header-title">
                        {/* {nameCutter(data.name)} */}
                        {data.name}
                    </span>
                </header>
                {compState.imageLoaded ?
                    <div className="upm-Img" style={{ backgroundImage: `url(${data.image})` }}>
                    </div> :
                    <div className="upm-Img" style={{ backgroundImage: `url(${notFound})` }}>
                    </div>
                }
                <button className="ump-info-button" onClick={() => descriptionSwitcher(true)}>
                    <img className="ump-info-button-img" src={info} alt="information-plate" />
                    <p className="ump-info-button-text">description</p>
                </button>
                <img src={data.image} alt={state.data.sharedId} onError={() => checkImage()} style={{ display: "none" }} />
                <div className="upm-footer">
                    <div className="upm-footerLeftCol">
                        <ul>
                            <li>{data.username !== null ? <span className="notranslate" translate="no">{data.username}</span> : null}</li>
                            {/* <li>{data.country}</li> */}
                            <a ref={shareButton} onClick={(event) => shareButtonFunc(event)} className="upm-button" href="sdfsd"><img src={share} alt="shere" /><span translate="no">Share</span></a>
                        </ul>
                    </div>
                    <div className="upm-footerRightCol">
                        <ul>
                            <li><span>Date</span><span>{dateFormatingAlter(data.date)}</span></li>
                            <li><span>price</span><span className="notranslate" translate="no">{priceFormatting(data.price)} {data.currency}</span></li>
                            <li><span>location</span><span>{data.location}</span></li>
                            <li><span>Category</span><span>{data.category}</span></li>
                            <li><span>Contact</span><span>{data.contact}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}