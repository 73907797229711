import { useEffect, useState, useContext, createContext, useReducer } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import UserPlate from './components/userPlate';
import Control from "./components/control";
import SideScreen from "./components/sideSlider"
import { EffectCoverflow, EffectFlip, EffectCube, EffectCards } from "swiper/modules"
import { useSearchParams, useNavigate } from "react-router-dom";
import PopUp from "./components/popup";
import Loader from './components/loading'
import initialState from "./context/initialState";
import Description from "./components/description";
import { ToastContainer } from 'react-toastify';
import reducer from "./context/reducer"
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'swiper/css';
import "swiper/css/effect-flip"
import "swiper/css/effect-cube"
import "swiper/css/effect-coverflow"
import "swiper/css/effect-cards"

export const AppState = createContext(initialState)

function App() {
  const systemLang = window.navigator.language.slice(0, 2)
  const yatranslate = {
    lang: 'ru',
    langFirstVisit: systemLang
  }
  let newDate = Date.now()
  let expire = new Date(newDate + 284000000).toString().slice(0, 15)
  const navigate = useNavigate()
  const [appState, dispatch] = useReducer(reducer, initialState)
  const [search, setSearchParams] = useSearchParams()
  const requared = search.get("share")

  function getDefaultData() {
    try {
      fetch("https://indoads.terrapay.online/api/items/")
        .then(response => !response.ok ? dispatch({ type: "server_error" }) : response.json())
        .then(json =>json.details !== "Items not found"  ? dispatch({ type: "starting", payload: json }) : null)
    } catch {

    }
  }

  //requesting language and reload page
  // document.cookie = `googtrans=/auto/${localStorage.getItem("Language")}, path=/slider-app`;

  function callForMoarItems() {
    if (appState.filterType !== "none") {
      try {
        if (appState.filterType !== "search" && appState.filterType !== "getting_buy_id") {
          fetch(`https://indoads.terrapay.online/api/items/?category=${appState.filterType}&page=${appState.page + 1}`)
            .then(response => !response.ok ? dispatch({ type: "server_error" }) : response.json())
            .then(json =>json.details === "Items not found" ? null : dispatch({ type: "increase_page_category", payload: [json, appState.filterWord] }))
        }
      }
      catch {
        console.warn("ServerError on getting item by Id")
      }
    }
    if (appState.filterType === "none") {
      console.log("none")
      console.log(appState.startData)
      try {
        if (appState.startData.length > 4) {
          fetch(`https://indoads.terrapay.online/api/items/?page=${appState.page + 1}`)
            .then(response => !response.ok ? dispatch({ type: "server_error" }) : response.json())
            .then(json => json.details === "Items not found" ? null : dispatch({ type: "increase_page", payload: json }))
        }

      }
      catch {
        console.warn("ServerError on getting item by Id")
      }
    }
    if (appState.nextPage && appState.filterType === "search") {
      try {
        fetch(`https://indoads.terrapay.online/api/items/search?query=${appState.searchWord}&page=${appState.page + 1}`)
          .then(response => !response.ok ? dispatch({ type: "server_error" }) : response.json())
          .then(json => json === undefined ? null : dispatch({ type: "filter_data_increase", payload: [json, appState.searchWord] }))
      }
      catch {
        console.warn("ServerError on getting item by Id")
      }
    } else {

    }
  }
  useEffect(() => {
  }, [])
  //checking browser lang. and sett as default
  // useEffect(() => {
  //   if (appState.sharedId !== "none") {
  // try {
  //   fetch(`https://indoads.terrapay.online/api/items/${appState.sharedId}`)
  //     .then(response => response.json())
  //     .then(json => dispatch({ type: "process_share", payload: json }))
  //     dispatch({ type: "load_ads", payload: "none"})
  //   navigate("/")
  // }
  // catch {
  //   console.warn("ServerError on getting item by Id")
  // }
  //   } else {
  //     getDefaultData()
  //   }
  // }, [appState.sharedId])
  //executting translate script
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   // Функция обратного вызова при загрузке скрипта Google Translate
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement(
  //       {
  //         pageLanguage: "auto",
  //         layout: window.google.translate.TranslateElement.InlineLayout
  //           .SIMPLE,
  //         autoDisplay: false,
  //       },
  //       "google_translate_element"
  //     );
  //   }
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [])

  //Yandex translate
  useEffect(() => {
    document.addEventListener('DOMContentLoaded', function () {
      // Start
      yaTranslateInit();
    })

    function yaTranslateInit() {

      if (yatranslate.langFirstVisit && !localStorage.getItem('yt-widget')) {
        /* Если установлен язык перевода для первого посещения и в localStorage нет yt-widget */
        /* If the translation language is installed for the first visit and in localStorage no yt-widget */
        yaTranslateSetLang(yatranslate.langFirstVisit);
      }

      let script = document.createElement('script');
      script.src = `https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=${yatranslate.lang}&widgetTheme=light&autoMode=false`;
      document.getElementsByTagName('head')[0].appendChild(script);

      let code = yaTranslateGetCode();

      yaTranslateHtmlHandler(code);

      yaTranslateEventHandler('click', '[data-ya-lang]', function (el) {
        yaTranslateSetLang(el.getAttribute('data-ya-lang'));
        // Перезагружаем страницу
        // Reloading the page
        window.location.reload();
      })
    }

    function yaTranslateSetLang(lang) {
      // Записываем выбранный язык в localStorage объект yt-widget 
      // Writing the selected language to localStorage 
      localStorage.setItem('yt-widget', JSON.stringify({
        "lang": lang,
        "active": true
      }));
    }

    function yaTranslateGetCode() {
      // Возвращаем язык на который переводим
      // Returning the language to which we are translating
      return (localStorage["yt-widget"] !== null && JSON.parse(localStorage["yt-widget"]).lang !== undefined) ? JSON.parse(localStorage["yt-widget"]).lang : yatranslate.lang;
    }

    function yaTranslateHtmlHandler(code) {
      // Получаем язык на который переводим и производим необходимые манипуляции с DOM
      // We get the language to which we translate and produce the necessary manipulations with DOM 
      document.querySelector(`[data-ya-lang=${code}]`)?.classList.add('language_list__active')

    }

    function yaTranslateEventHandler(event, selector, handler) {
      document.addEventListener(event, function (e) {
        let el = e.target.closest(selector);
        if (el) handler(el);
      });
    }
    yaTranslateInit()
    return () => { }
  }, [])
  //execute telegramMiniApp
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      console.log(window.Telegram.WebApp.initData)
      window.Telegram.WebApp.setHeaderColor("#e3e3e3")
      window.Telegram.WebApp.setBackgroundColor("#ffffff")
      window.Telegram.WebApp.enableClosingConfirmation()
      let telegaData = window.Telegram.WebApp.initData
      if (telegaData.split("&")[3].split("start_param=")[1] !== undefined) {
        console.log(telegaData)
        try {
          fetch(`https://indoads.terrapay.online/api/items/${telegaData.split("&")[3].split("start_param=")[1]}`)
            .then(response => !response.ok ? dispatch({ type: "server_error" }) : response.json())
            .then(json => dispatch({ type: "process_share", payload: json }))
        }
        catch {
          console.warn("ServerError on getting item by Id")
        }
      } else {
        getDefaultData()
      }
    }
  }, []);

  return (
    <AppState.Provider value={{ data: appState, dispatch: dispatch }}>
      <div id="ytWidget" style={{ display: 'none' }}></div>
      <div className="App">
        {appState.startData.length > 0 ?
          <Swiper
            key={appState.restart}
            modules={[EffectCoverflow, EffectFlip, EffectCube, EffectCards]}
            direction={'vertical'}
            slidesPerView={2}
            spaceBetween={10}
            centeredSlides={true}
            mousewheel={true}
            className={"alterSwiper"}
            onReachEnd={() => callForMoarItems()}
            automaticallyAdjustContentInsets={true}
          >
            {console.log(appState.startData)}
            <PopUp />
            <Control />
            <SideScreen />
            <ToastContainer />
            {appState.descriptionWindow ? <Description /> : null}
            {appState.formatedData.map((element, index) => {
              return (
                <SwiperSlide >
                  {
                    <UserPlate data={element} key={index} />
                  }
                </SwiperSlide>
              )
            })}
          </Swiper> :
          <Loader />
        }
      </div>
    </AppState.Provider >
  );
}

export default App;
