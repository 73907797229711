import { AppState } from "../App"
import { useContext } from "react"
import "./description.css"

export default function Description() {
    const context = useContext(AppState)

    function descriptionSwitcher() {
        context.dispatch({ type: "close_description" })
    }

    return (
        < div className="userPlateMobile-description" >
            <div className=" userPlateMobile-description-header-controll">
                <span className="descriptionPopupCloser" onClick={() => descriptionSwitcher()}>X</span>
            </div>
            <div className="userPlateMobile-description-text ">
                {context.data.description}
            </div>
        </div >
    )
}