import { toast } from 'react-toastify';

const notify = () => toast("Advertisements not found or missing.",
    {
        position: "top-left",
        autoClose: 1700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light"
    }
);

export default function reducer(state, action) {
    switch (action.type) {
        case "redirect_to_main_page":
            return
        case "load_ads":
            return { ...state, sharedId: action.payload }
        case "shared_id":
            return { ...state, sharedId: action.payload }
        case "reremder_slider":
            return
        case "increase_page":
            return {
                ...state,
                startData: state.startData.concat(action.payload.items),
                formatedData: state.startData.concat(action.payload.items),
                nextPage: action.payload.next_page,
                filterType: "none",
                page: state.page + 1
            }
        case "filter":
            if (action.payload[0] === "language" || action.payload[0] === "filter") {
                return { ...state, filter: action.payload[0], sideWindow: action.payload[1] }
            } if (action.payload[0] === "search") {
                return { ...state, filter: action.payload[0], sideWindow: action.payload[1] }
            }
        case "increase_page_category": {
            return {
                ...state,
                filterWord: action.payload[1],
                startData: state.startData.concat(action.payload[0].items),
                formatedData: state.startData.concat(action.payload[0].items),
                nextPage: action.payload[0].next_page,
                page: state.page + 1
            }
        }
        case "doncat_data":
            return { ...state, formatedData: state.formatedData.push(action.payload.items) }
        case "switch_side_window":
            return { ...state, sideWindow: action.payload }
        case "change_language":
            document.cookie = `googtrans=/auto/${action.payload}`
            return { ...state, language: state.language + 1, }
        case "filter_data":
            if (action.payload === null
                || action.payload[0].detail === "Products not found"
                || action.payload[0].detail === "Items not found"
                || action.payload[0].detail === "Category not found") {
                console.log("search")
                notify()
                return {
                    ...state,
                    formatedData: state.startData,
                    filterType: "search"
                }
            } else {
                console.log(action.payload)
                return {
                    ...state,
                    restart: !state.restart,
                    startData: state.startData,
                    formatedData: action.payload[0].items,
                    nextPage: action.payload[0].next_page,
                    filterType: "search",
                    searchWord: action.payload[1],
                    page: 1
                }
            }
        case "filter_data_increase":
            if (action.payload === null
                || action.payload.detail === "Products not found"
                || action.payload.detail === "Items not found"
                || action.payload.detail === "Category not found") {
                return { ...state, formatedData: state.startData, filterType: "search" }
            } else {
                console.log(action.payload)
                return {
                    ...state, startData: state.startData.concat(action.payload[0].items),
                    formatedData: state.startData.concat(action.payload[0].items),
                    nextPage: action.payload[0].next_page,
                    filterType: "search",
                    searchWord: action.payload[1],
                    page: state.page + 1
                }
            }
        case "filter_category":
            if (action.payload === null
                || action.payload[0].detail === "Products not found"
                || action.payload[0].detail === "Items not found"
                || action.payload[0].detail === "Category not found") {
                notify()
                return { ...state, formatedData: state.startData }
            } else {
                console.log(action.payload)
                return {
                    ...state,
                    restart: !state.restart,
                    categoryId: action.payload[1],
                    formatedData: action.payload[0].items,
                    startData: action.payload[0].items,
                    filterType: action.payload[2],
                    searchWord: action.payload[2],
                    page: 1
                }
            }
        case "restart_state_data":
            return { ...state, formatedData: state.mockData }
        case "process_share":
            if (action.payload === undefined) {
                return { ...state, filterType: "getting_buy_id", startData: [], formatedData: [] }
            }
            return { ...state, filterType: "getting_buy_id", startData: [action.payload], formatedData: [action.payload] }
        case "starting":
            return {
                ...state,
                restart: !state.restart,
                filterType: "none",
                startData: action.payload.items,
                formatedData: action.payload.items,
                page: action.payload.page,
                nextPage: action.payload.next_page
            }
        case "close_description":
            return { ...state, descriptionWindow: false }
        case "open_description":
            return { ...state, description: action.payload, descriptionWindow: true }
        case "server_error":
            return { ...state,  startData: [], formatedData: [] }
        case "popup":
            return { ...state, popup: state.popup + 1 }
        default:
            console.log("Reducer error")
    }
}