const initialState = {
    filter: "none",
    filterType: "none",
    sideWindow: "closed",
    searchWord: "",
    popup: 0,
    language: 0,
    sharedId: "none",
    restart: false,
    categoryId: "none",
    page: 1,
    nextPage: true,
    description: "",
    descriptionWindow: false,
    startData: [],
    formatedData: []
}

export default initialState;