import { useContext, useEffect, useState } from "react"
import { AppState } from "../App"
import home from "../img/home.svg"
import "./loadingControl.css"

export default function Loadingcontrol() {
    const state = useContext(AppState)
    const [compState, setCompState] = useState("waiting")

    function waiting() {
        setTimeout(() => {
            setCompState("ready")
        }, 300)
    }
    function getDefaultData() {
        try {
            fetch("https://indoads.terrapay.online/api/items/")
                .then(response =>!response.ok ? state.dispatch({ type: "server_error" }) : response.json())
                .then(json =>json !==undefined ? state.dispatch({ type: "starting", payload: json }): null)
        } catch {

        }
    }

    function redirectToMain() {
        waiting()
        getDefaultData()
    }
    useEffect(() => {
        return () => { setCompState("waiting") }
    }, [])
    return (
        <>
            <div className={compState === "ready" ? "sidebar-controller-loader" : "sidebar-controller-loader closing"}>
                <div className="sidebar-settings"
                    onClick={() => redirectToMain()}
                ><img src={home} alt="home" /></div>
            </div>
        </>
    )
}