import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppState } from "../App"
import { gsap } from "gsap";
import { useLocalStorage } from "../hooks/useLocalStorage";
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


import "./sideSlider.css"

export default function SideScreen() {
    const [lang, setLang] = useLocalStorage()
    const sideWindow = useRef()
    const downWindow = useRef()
    const search = useRef()
    const state = useContext(AppState)
    const navigate = useNavigate()
    const [sliderRef, instanceRef] = useKeenSlider({
        slides: {
            origin: "center",
            perView: "auto",
            spacing: 10,
        },
        drag: true,
        vertical: true,
    })

    const [compState, setCompState] = useState({
        searchField: "",
        selected: false,
        categories: [],
        activeSlider: state.data.categoryId,
        rerender: state.data.filter,
        rerender2: state.data.sideWindow
    })

    const languages = [
        ["ru", "Русский"],
        ["en", "English"],
        ["be", "Беларуская"],
        ["uk", "Українська"],
        ["tr", "Türkçe"],
        ["pl", "Polski"],
        ["ja", "日本語"],
        ["it", "Italiano"],
        ["de", "Deutsch"],
        ["fr", "Français"]
    ]
    function categoryCutter(value) {
        if (value.length > 15) {
            return value.slice(0, 13) + "..."
        } else {
            return value
        }
    }
    function filterRequest(filterName, filterId, event) {
        closeModal(event)
        try {
            fetch(`https://indoads.terrapay.online/api/items/?category=${filterName}&page=1`)
                .then(response => response.json())
                .then(json =>
                    state.dispatch({ type: "filter_category", payload: [json, filterId, filterName] })
                )
            setCompState({ ...compState, activeSlider: filterId })
        }
        catch {
            console.warn("Filter request error")
        }
    }
    function switchFilters(screen) {
        switch (screen) {
            case "filter":
                return (
                    <div ref={sideWindow} className="side-screen " onClick={(event) => closeModal(event)}>
                        <div ref={sliderRef} className="keen-slider" style={{ maxHeight: "100dvh", maxWidth: "100%" }}>
                            {compState.categories.length > 0 ?
                                compState.categories.map((element, index) =>
                                    <span key={index} className="keen-slider__slide" style={{ minHeight: 70, maxWidth: "100%" }}>
                                        <li onClick={(e) => filterRequest(element.name, element.id, e)}
                                            className="filter-List-item"
                                        ><span className={state.data.categoryId === element.id ? "underline-text" : ""}>{element.name}</span></li>
                                    </span>
                                ) : null}
                        </div>
                    </div >
                )
            case "language":
                return (
                    <div ref={sideWindow} className="side-screen" onClick={(event) => closeModal(event)}>
                        <ul className="filter-List-lang notranslate">
                            {
                                languages.map((element, index) => {
                                    return <a key={index} href="/"
                                        className={localStorage.getItem("Language") === element[0] ? "link underline" : "link"}
                                        onClick={(e) => { languageSelect(element[0], e) }}><span>{element[1]}</span></a>
                                })
                            }
                        </ul >
                    </div >
                )
            case "search":
                return (
                    null
                )
            default:
                console.warn("Error in filter")
        }
    }
    function searchModalPrevent(event) {
        event.preventDefault()
        event.stopPropagation();
    }
    function closeModal(event) {
        state.dispatch({ type: "switch_side_window", payload: "closed_side" })
    }
    function closeDownModals(event) {
        event.preventDefault()
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        state.dispatch({ type: "switch_side_window", payload: "closed_side" })
        state.dispatch({ type: "switch_side_window", payload: "closed_down" })
    }
    function languageSelect(value, event) {
        event.preventDefault()
        // setLang(value)
        // state.dispatch({ type: "change_language", payload: value })
    }
    function searchInput(value) {
        setCompState({ ...compState, searchField: value.target.value })
    }
    function sendSearchData(event) {
        closeDownModals(event)
        try {
            fetch(`https://indoads.terrapay.online/api/items/search?query=${compState.searchField}&page=1`)
                .then(response => response.json())
                .then(json => state.dispatch({ type: "filter_data", payload: [json, compState.searchField] }))
        }
        catch {
            console.warn("Search error")
        }
    }

    useEffect(() => {

        function focusInput() {
            setTimeout(() => {
                setCompState({ ...compState, selected: true })
            }, 1500)
        }
        function unFocusInput() {
            setCompState({ ...compState, selected: false })
        }
        //open side window and close downsearch
        if (state.data.sideWindow === "opened_side") {
            if (state.data.filter !== "search") {
                gsap.to(sideWindow.current, {
                    xPercent: -100,
                    onComplete: unFocusInput()
                })
                gsap.to(downWindow.current, {
                    zIndex: -1,
                    opacity: 0
                })
            }
        }
        //open downsearch and close side window
        if (state.data.sideWindow === "opened_down") {
            if (state.data.filter === "search") {
                gsap.to(sideWindow.current, {
                    xPercent: 0
                })
                gsap.to(downWindow.current, {
                    zIndex: 11,
                    opacity: 1,
                    onComplete: focusInput()
                })
            }
        }
        //close side window and searchdown window
        if (state.data.sideWindow === "closed_side") {
            if (state.data.filter !== "search") {
                gsap.to(sideWindow.current, {
                    xPercent: 0,
                    onComplete: unFocusInput()
                })
                gsap.to(downWindow.current, {
                    zIndex: -1,
                    opacity: 0
                })
            }
        }
        //close searchdown window only
        if (state.data.sideWindow === "closed_down") {
            if (state.data.filter === "search") {
                gsap.to(downWindow.current, {
                    zIndex: -1,
                    opacity: 0,
                    onComplete: unFocusInput()
                })
                gsap.to(sideWindow.current, {
                    xPercent: 0
                })
            }
        }
    }, [state.data.filter, state.data.sideWindow])

    useEffect(() => {
        console.log("yep")
        fetch(`https://indoads.terrapay.online/api/categories/`)
            .then(response => response.json())
            .then(json => setCompState({ ...compState, categories: json }))
    }, [])
    return (
        <>
            {
                state.data.filter === "filter" ?
                    <div ref={sideWindow} className="side-screen " onClick={(event) => closeModal(event)}>
                        <Swiper
                            direction={'vertical'}
                            slidesPerView={"auto"}
                            spaceBetween={40}
                            centeredSlides={false}
                            mousewheel={true}
                            className={"alterSwiperSider"}
                            initialSlide={state.data.categoryId}
                        // automaticallyAdjustContentInsets={true}
                        >
                            <div id="ytWidget" style={{ display: 'none' }}></div>
                            {compState.categories.length > 0 ?
                                compState.categories.map((element, index) =>
                                    <SwiperSlide>
                                        <span className="">
                                            <li onClick={(e) => filterRequest(element.name, element.id, e)}
                                                className="filter-List-item"
                                            ><span className={state.data.categoryId === element.id ? "underline-text" : ""}>{element.name}</span></li>
                                        </span>
                                    </SwiperSlide>
                                ) : null}

                        </Swiper>
                    </div > :
                    <div ref={sideWindow} className="side-screen" onClick={(event) => closeModal(event)}>
                        <ul className="filter-List-lang notranslate">
                            {
                                languages.map((element, index) => {
                                    return <a key={index} data-ya-lang={element[0]} translate="no"
                                        className={JSON.parse(localStorage.getItem("yt-widget")).lang === element[0] ? "link underline" : "link"}
                                        onClick={(e) => { languageSelect(element[0], e) }}><span>{element[1]}</span></a>
                                })
                            }
                        </ul>
                    </div >
            }
            <div ref={downWindow} className="down-screen" onClick={(event) => closeDownModals(event)}>
                <ul className="filter-List-search" onClick={(e) => { searchModalPrevent(e) }}>
                    <li className="filterSearch">
                        <h2>Search advertisements</h2>
                        <input type="text" id="input" className={compState.selected ? "selectedInput" : ""} ref={search} value={compState.searchField} onChange={(e) => searchInput(e)} placeholder="Search" />
                        <button onClick={(event) => sendSearchData(event)}>Search</button>
                    </li>
                </ul >
            </div>
        </>
    )
}